<template>
  <div class="bg-gray-800">
    <div class="flex flex-wrap">
      <div class="w-full">
        <InputWrapper
          type="info"
          :label="node.type"
          :extra="extraInfo"
        />
      </div>
    </div>
  </div>
</template>
<script>
import mixin from './NodeSettingMixin'

export default {
  name: 'NodeInfoBlock',

  mixins: [mixin],

  computed: {
    extraInfo () {
      if (this.isScene) {
        return 'The Scene is the root block'
      }

      if (this.isGlobalLight) {
        return 'A Global light, added automatically. Configure settings from the Viewer > Global Lighting tab'
      }

      if (this.isIgnoredGroup) {
        return 'Automatically added group, containing viewer helper objects'
      }

      if (this.isMesh) {
        return 'You can modify the base texture map'
      }

      if (this.isLight) {
        return 'Lights provide illumination to the scene'
      }

      if (this.isObject3D) {
        return 'Object3Ds act as holders for any arbitrary other scene objects'
      }

      return null
    }
  }
}
</script>
